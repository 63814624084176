import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql } from 'gatsby';
import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';

import ContentCol from '../components/contentCol/contentCol';
import Layout from '../components/layout/layout';
import SeoPress from '../components/seoPress/seoPress';
import Wysiwyg from '../components/wysiwyg/wysiwyg';

const ProvidersPage = ({ data }) => {
  return (
    <Layout navbarOnWhite={true}>
      <SeoPress
        seo={data.wpSitePage.seo}
        title={data.wpSitePage.title}
        uri={`${data.wpSitePage.slug}`}
      />

      <main className="providers">
        <Container className="pt-7">
          <Row className="justify-content-center">
            <ContentCol>
              <Wysiwyg
                html={data.wpSitePage.pageSiteProviders.headerContent}
                className="text-center"
              />
            </ContentCol>
          </Row>
        </Container>

        <hr className="w-50 my-4" />

        <section>
          <Container>
            <Row className="justify-content-center">
              <ContentCol>
                <Wysiwyg
                  html={data.wpSitePage.pageSiteProviders.membershipContent}
                  className="text-center"
                />
              </ContentCol>
            </Row>

            {data.wpSitePage.pageSiteProviders.memberships && (
              <Row className="justify-content-center align-items-center mt-4">
                {data.wpSitePage.pageSiteProviders.memberships.map(
                  (membership, index) => (
                    <Col xs={12} lg={4} className="mb-3" key={index}>
                      <Card className={'overflow-hidden'}>
                        <div className="text-center bg-light p-2">
                          <h3>{membership.title}</h3>
                          <p className="mb-0" style={{ lineHeight: 1 }}>
                            ${membership.price} per year
                            <br />
                            <small>{membership.priceCaption}</small>
                          </p>
                        </div>

                        <div className="p-3 membership-features">
                          <Wysiwyg html={membership.features} />
                        </div>
                      </Card>
                    </Col>
                  )
                )}
              </Row>
            )}

            <div className="text-center mt-3">
              <a
                href="mailto:editor@mindfulescapes.com"
                className="btn btn-primary d-inline-block"
              >
                Apply For Membership
              </a>
            </div>
          </Container>
        </section>

        <hr className="w-50 my-5" />

        <section className="pb-7">
          <Container>
            <Row className="justify-content-center">
              <ContentCol>
                <Wysiwyg
                  html={data.wpSitePage.pageSiteProviders.serviceContent}
                  className="text-center"
                />
              </ContentCol>
            </Row>

            {data.wpSitePage.pageSiteProviders.services && (
              <Row className="mt-4 justify-content-center">
                <Col xs={12} lg={10} xl={9}>
                  {data.wpSitePage.pageSiteProviders.services.map(
                    (service, index) => (
                      <Card className={'overflow-hidden mb-3'} key={index}>
                        <Row className="no-gutters">
                          <Col
                            xs={2}
                            sm={3}
                            className="bg-light d-flex justify-content-center"
                          >
                            <div
                              className="d-flex align-self-center mx-auto"
                              style={{ maxWidth: '50%' }}
                            >
                              <FontAwesomeIcon
                                icon={['fal', service.icon]}
                                size={'3x'}
                                style={{ maxWidth: '100%' }}
                              />
                            </div>
                          </Col>
                          <Col xs={10} sm={9} className="p-3">
                            <h3 className="mb-1">{service.title}</h3>
                            <p className="mb-3" style={{ lineHeight: 1 }}>
                              {service.price}
                            </p>
                            <Wysiwyg
                              html={service.description}
                              className="service-description"
                            />
                          </Col>
                        </Row>
                      </Card>
                    )
                  )}
                </Col>
              </Row>
            )}

            <div className="text-center mt-4">
              <a
                href="mailto:editor@mindfulescapes.com"
                className="btn btn-primary d-inline-block"
              >
                Request Services
              </a>
            </div>
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export const query = graphql`
  query {
    wpSitePage(databaseId: { eq: 634 }) {
      title
      slug
      seo {
        title
        metaDesc
        metaKeywords
        canonical
        opengraphUrl
        opengraphType
        opengraphTitle
        opengraphDescription
        opengraphImage {
          localFile {
            publicURL
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          localFile {
            publicURL
          }
        }
      }
      pageSiteProviders {
        headerContent
        membershipContent
        serviceContent
        memberships {
          title
          price
          priceCaption
          features
        }
        services {
          title
          price
          description
          icon
        }
      }
    }
  }
`;

export default ProvidersPage;
